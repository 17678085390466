import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87aa92fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formarea" }
const _hoisted_2 = { class: "btnbox" }
const _hoisted_3 = {
  key: 1,
  class: "submit-btn",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_form = _resolveComponent("van-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _ctx.fGoBack
    }, null, 8, ["title", "onClickLeft"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_form, {
        "show-error-message": false,
        "show-error": true,
        "label-width": "100px",
        ref: "form",
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_field, {
            modelValue: _ctx.submitform.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.submitform.title) = $event)),
            rules: [{ required: true, message: '巡检名称必填' }],
            required: "",
            label: "巡检名称",
            placeholder: "请填写巡检名称",
            maxlength: "50",
            "input-align": "right"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.areaName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.areaName) = $event)),
            "is-link": "",
            readonly: "",
            required: "",
            label: "所在区域",
            placeholder: "请选择所在区域",
            "input-align": "right",
            onClick: _ctx.fOpenAreaPicker,
            rules: [{ required: true, message: '请选择所在区域' }]
          }, null, 8, ["modelValue", "onClick"]),
          _createVNode(_component_van_popup, {
            round: "",
            show: _ctx.showAreaPicker,
            "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAreaPicker) = $event)),
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_picker, {
                title: "所在区域",
                columns: _ctx.areaColumns,
                "columns-field-names": { text: 'name' },
                onConfirm: _ctx.onAreaConfirm,
                onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAreaPicker = false))
              }, null, 8, ["columns", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.submitform.deptName,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.submitform.deptName) = $event)),
            required: "",
            label: "部门",
            "input-align": "right",
            placeholder: "请填写部门",
            rules: [{ required: true, message: '请填写部门' }]
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.limitDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.limitDate) = $event)),
            "is-link": "",
            readonly: "",
            required: "",
            label: "限定整改时间",
            "input-align": "right",
            placeholder: "请选择",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDatePopup = true)),
            rules: [{ required: true, message: '请指定限定整改时间' }]
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_popup, {
            show: _ctx.showDatePopup,
            "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDatePopup) = $event)),
            position: "bottom",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_datetime_picker, {
                modelValue: _ctx.selectDate,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectDate) = $event)),
                type: "date",
                title: "选择年月日",
                onConfirm: _ctx.fConfirmDate,
                onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showDatePopup = !_ctx.showDatePopup))
              }, null, 8, ["modelValue", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.submitform.remark,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.submitform.remark) = $event)),
            label: "备注",
            type: "textarea",
            maxlength: "400",
            rows: "2",
            placeholder: "请填写备注",
            "show-word-limit": ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_field, {
            class: "fujian",
            disabled: "",
            label: "附件"
          }),
          _createVNode(_component_van_field, {
            name: "uploader",
            style: {"background":"transparent"}
          }, {
            input: _withCtx(() => [
              _createVNode(_component_van_uploader, {
                modelValue: _ctx.uploadFiles,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.uploadFiles) = $event)),
                "after-read": _ctx.afterRead,
                multiple: _ctx.$isIOS()
              }, null, 8, ["modelValue", "after-read", "multiple"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.bAllow)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "submit-btn",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.fSumbitForm && _ctx.fSumbitForm(...args)))
                }, "提交"))
              : _createCommentVNode("", true),
            (!_ctx.bAllow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, "提交"))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ], 64))
}